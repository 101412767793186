export const CONNECT_WALLET = "CONNECT_WALLET";

export const DISCONNECT_WALLET = "DISCONNECT_WALLET";

export const SPINNER_SHOW = "SPINNER_SHOW";

export const STAKE_VALUE = "STAKE_VALUE";

export const UNSTAKE_VALUE = "UNSTAKE_VALUE";

export const TOAST_SHOW = "TOAST_SHOW";

export const EXPORT_ALLSTAKES = "EXPORT_ALLSTAKES";

export const GET_BALANCEOFREWARDS = "GET_BALANCEOFREWARDS";

export const GET_HISTORY = "GET_HISTORY";

export const GET_UNSTAKEFEEPERCENT = "GET_UNSTAKEFEEPERCENT";